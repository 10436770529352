import React, { useState, useEffect } from 'react';
import { Spinner } from '@zydalabs/zac-react';
import cx from 'classnames';

import { useMobile } from 'hooks';
import { ACTIVE_ORDERS, LAST_30_DAYS, VIEW_ALL_ORDERS } from 'constants/translations';
import * as paths from 'paths.js';
import { ORDER_STATUS } from 'constants/order';
import { useFetchActiveOrders } from 'service/hooks/backend';

import OrderCardsList from './OrderCardsList';
import SectionTitle from '../common/SectionTitle';

const ActiveOrders: React.FC = () => {
  const [activeOrders, setActiveOrders] = useState<any>([]);
  const isMobile = useMobile();

  const { data: ordersData, isLoading: isLoadingOrders } = useFetchActiveOrders({
    statuses: [ORDER_STATUS.SUBMITTED, ORDER_STATUS.ACCEPTED],
  });

  useEffect(() => {
    if (!isLoadingOrders && !!ordersData) {
      setActiveOrders(ordersData);
    }
  }, [isLoadingOrders, ordersData]);

  return (
    <div className="pb-12">
      <SectionTitle
        title={ACTIVE_ORDERS}
        subtitle={LAST_30_DAYS}
        linkText={VIEW_ALL_ORDERS}
        linkPath={paths.allOrders}
      />
      <div className={cx('py-6', isMobile && 'overflow-x-auto')}>
        {isLoadingOrders ? <Spinner radius={20} /> : <OrderCardsList activeOrders={activeOrders} />}
      </div>
    </div>
  );
};

export default ActiveOrders;
