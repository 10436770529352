export const unauthenticated = isAuthenticated => !isAuthenticated;

export const storeCreated = (isAuthenticated, { isStoreCreated, isLoadingStores, isLoadingUser } = {}) =>
  (isAuthenticated && isStoreCreated) || isLoadingStores || isLoadingUser;

export const storeCreatedAndAvail = (
  isAuthenticated,
  { isStoreCreated, blockTabs, isLoadingStores, isLoadingUser } = {},
) => (isAuthenticated && isStoreCreated && !blockTabs) || ((isLoadingStores || isLoadingUser) && !blockTabs);

export const storeNotCreated = (isAuthenticated, { isStoreCreated, isLoadingStores, isLoadingUser } = {}) =>
  isAuthenticated && !isStoreCreated && !isLoadingStores && !isLoadingUser;

export const storeCreatedAndSubscriped = (
  isAuthenticated,
  { isStoreCreated, blockTabs, subscription, isLoadingStores, isLoadingUser } = {},
) => {
  if (subscription === 'late_payment') {
    return (isAuthenticated && isStoreCreated) || isLoadingStores || isLoadingUser;
  }
  return (isAuthenticated && isStoreCreated && !blockTabs) || ((isLoadingStores || isLoadingUser) && !blockTabs);
};
