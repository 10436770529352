import React, { useContext } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@zydalabs/zac-icons-react';
import { Tag, Typography, useTheme } from '@zydalabs/zac-react';
import cx from 'classnames';

import { context as localeContext } from 'context/locale';
import { usePriceFormat } from 'hooks';

type CardDataProps = {
  title: string;
  info?: number;
  infoInCurrency?: number;
  delta: string;
  isHighRate: boolean;
};
const CardData: React.FC<CardDataProps> = ({ title, info, delta, isHighRate, infoInCurrency }) => {
  const { colors } = useTheme();
  const { lang } = useContext(localeContext);
  const formatPrice = usePriceFormat();

  return (
    <div className={cx('pb-4', lang === 'en' ? 'mr-4' : 'ml-4')}>
      <div className="w-[220px] h-[80px] px-3 py-3" style={{ backgroundColor: colors.positive.background }}>
        <div className="flex flex-col">
          <div className="flex flex-row pb-2">
            <Typography variant="element14">{title}</Typography>
          </div>
          <div className="flex flex-row justify-between">
            <div>
              <Typography variant="heading16">{info}</Typography>
              {infoInCurrency >= 0 && <Typography variant="heading16">{formatPrice(infoInCurrency)}</Typography>}
            </div>

            <div className="flex">
              <Tag
                color={isHighRate ? 'success' : 'danger'}
                inner={{
                  text: delta,
                  icon: isHighRate ? <ChevronUpIcon /> : <ChevronDownIcon />,
                }}
                size="small"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CardData;
