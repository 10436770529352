import * as paths from 'paths.js';

export const redirectTo = (
  isAuthenticated,
  { isStoreCreated, storeId, blockTabs, isLoadingStores, isLoadingUser } = {},
) => {
  if (!isAuthenticated) {
    return paths.signIn;
  }

  if (isStoreCreated && storeId) {
    return blockTabs ? '/' + storeId + '/billing/invoices' : '/' + storeId;
  }

  if (!isStoreCreated && !isLoadingStores && !isLoadingUser) {
    return paths.onboarding.storeCreation;
  }

  return '/';
};
