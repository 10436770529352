import React, { useContext, useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import cx from 'classnames';
import { RemoveScroll } from 'react-remove-scroll';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import { Spinner } from '@zydalabs/zac-react';

import { context as localeContext } from 'context/locale';
import { context as modal } from 'context/modal';
import { context as userContext } from 'context/user';
import { context as featureBlockingContext } from 'context/feature-blocking';
import { useMobile } from 'hooks/index';
import { ORDER_RING_OPTIONS } from 'constants/orderRingerEnums';
import { ACCESS_MAP_KEYS } from 'constants/featureAccessMatrix';
import Sidebar from './Sidebar';
import MobileSidebar from './MobileSidebar';
import RenewTopBar from 'pages/billing/Renew/TopbarRenew';
import BlockedFeature from 'common/components/BlockedFeature';
import FeatureAccess from 'common/lib/FeatureAccess';
import { npsBox } from '../../../service/Vitally';

const Layout = ({
  top,
  hideSidebar = false,
  children,
  shimmeringChildren = false,
  direction = 'ltr',
  setTopPanel = undefined,
  hasSticky = false,
}) => {
  const isMobile = useMobile();
  const { lang, isDisconnected, translate } = useContext(localeContext);
  const { divRef } = useContext(modal);
  const { setStopRing, isSidebarOpened, setSidebarOpened, selectedStore, isLoadingStores } = useContext(userContext);
  const { featureAccess } = useContext(featureBlockingContext);
  const layoutAccess = featureAccess?.get(ACCESS_MAP_KEYS.LAYOUT);

  const isIPHONE = isMobile && navigator.userAgent.match(/iPhone/i);
  const isIPAD = navigator.userAgent.match(/(iPad|iPod)/i);

  const location = useLocation();
  const layoutFeatureAccess =
    selectedStore?.id && layoutAccess
      ? layoutAccess?.get(FeatureAccess.getFeaturePath(location?.pathname, selectedStore?.id))
      : { isBlocked: false };

  const checkElement = async selector => {
    while (document.querySelector(selector) === null) {
      await new Promise(resolve => requestAnimationFrame(resolve));
    }
    return document.querySelector(selector);
  };

  useEffect(() => {
    checkElement('.Vitally--reset .Vitally--NpsSurveyView-container').then(() => {
      npsBox(isMobile);
    });
    isMobile && document.body.setAttribute('style', 'margin-right:0px !important');
  }, []);

  useEffect(() => {
    window.intercomSettings = {
      ...window.intercomSettings,
      language_override: lang,
      alignment: lang === 'ar' ? 'left' : 'right',
    };
  }, [lang]);

  const scrollBottom = () => {
    const scrollView = document.getElementById('scroll-view');
    if (scrollView.scrollTop > 65) {
      setTopPanel && setTopPanel(true);
    } else {
      setTopPanel && setTopPanel(false);
    }
  };

  return (
    <div>
      <GlobalStyle />
      <RemoveScroll shards={[divRef]} forwardProps>
        <div onClick={() => setStopRing(ORDER_RING_OPTIONS.STOP)}>
          <RenewTopBar />
          <div className="flex flex-col text-sm">
            <div
              className={cx(
                'flex',
                lang === 'ar' && 'flex-row-reverse',
                isMobile && 'overflow-y-auto',
                'flex-1 w-full h-screen',
                isIPAD && 'pb-16',
              )}
              style={{
                filter: isDisconnected && 'grayscale(100%)',
              }}
            >
              {!hideSidebar && (
                <>
                  {!isMobile && <Sidebar />}
                  {isMobile && <MobileSidebar isVisible={isSidebarOpened} onClose={() => setSidebarOpened(false)} />}
                </>
              )}
              <div
                className={cx(hasSticky && isMobile ? 'h-screen' : 'h-auto', 'overflow-y-auto flex flex-col flex-1')}
                id="scroll-view"
                onScroll={scrollBottom}
              >
                {top}
                <div
                  style={{ direction }}
                  className={cx(
                    hasSticky ? 'pb-0' : isIPHONE ? 'pb-48' : 'pb-40',
                    'flex flex-col flex-1 xl:px-6 px-4 pt-4',
                    !hasSticky && 'md:pb-16',
                  )}
                >
                  {!layoutFeatureAccess?.isBlocked ? (
                    isLoadingStores ? (
                      shimmeringChildren || <Spinner />
                    ) : (
                      children
                    )
                  ) : (
                    <BlockedFeature
                      featureTitle={layoutFeatureAccess?.featureTitle}
                      featureDescription={layoutFeatureAccess?.featureDescription}
                      featureListTitle={layoutFeatureAccess?.featureListTitle}
                      featureList={layoutFeatureAccess?.featureList}
                      translate={translate}
                      featureDesktopImage={layoutFeatureAccess?.featureDesktopImage}
                      featureMobileImage={layoutFeatureAccess?.featureMobileImage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </RemoveScroll>
    </div>
  );
};

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

Layout.propTypes = {
  top: PropTypes.element.isRequired,
  hideSidebar: PropTypes.bool,
  children: PropTypes.element.isRequired,
  direction: PropTypes.oneOf(['ltr', 'rtl']),
  setTopPanel: PropTypes.func,
  hasSticky: PropTypes.bool,
  shimmeringChildren: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
};

export default Layout;
