import React from 'react';
import { Spinner } from '@zydalabs/zac-react';
import { LAST_30_DAYS, SUMMARY, VIEW_SALES_ANALYTICS } from 'constants/translations';
import * as paths from 'paths.js';
import { useFetchAnalyticsSummary } from 'service/hooks/backend';
import SectionTitle from '../common/SectionTitle';
import CardData from './CardData';

const Summary: React.FC = () => {
  const { data: summaryData, isLoading } = useFetchAnalyticsSummary();

  const {
    totalOrders,
    ordersPercentDiff,
    avgOrderValue,
    avgOrderPercentDiff,
    totalSales,
    salesPercentDiff,
    newCustomers,
    newCustomersPercentDiff,
    returningCustomers,
    returningCustomersPercentDiff,
  } = summaryData || {};

  return (
    <div>
      <SectionTitle
        title={SUMMARY}
        subtitle={LAST_30_DAYS}
        linkText={VIEW_SALES_ANALYTICS}
        linkPath={paths.salesAnalytics}
      />
      <div className="py-6 flex w-full">
        {isLoading ? (
          <Spinner radius={20} />
        ) : (
          <>
            <CardData
              title="Orders"
              info={totalOrders}
              delta={`${ordersPercentDiff}%`}
              isHighRate={ordersPercentDiff > 0}
            />
            <CardData
              title="Avg. Order Size"
              infoInCurrency={avgOrderValue}
              delta={`${avgOrderPercentDiff}%`}
              isHighRate={avgOrderPercentDiff > 0}
            />
            <CardData
              title="Total Sales"
              infoInCurrency={totalSales}
              delta={`${salesPercentDiff}%`}
              isHighRate={salesPercentDiff > 0}
            />
            <CardData
              title="New Customers"
              info={newCustomers}
              delta={`${newCustomersPercentDiff} %`}
              isHighRate={newCustomersPercentDiff >= 1}
            />
            <CardData
              title="Returning Customers"
              info={returningCustomers}
              delta={`${returningCustomersPercentDiff} %`}
              isHighRate={returningCustomersPercentDiff >= 1}
            />
          </>
        )}
      </div>
    </div>
  );
};
export default Summary;
