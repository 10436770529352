import React from 'react';
import { Spinner } from '@zydalabs/zac-react';

import { LAST_30_DAYS, TOP_FIVE_SALES } from 'constants/translations';
import { useFetchTopSellingItems } from 'service/hooks/backend';
import SectionTitle from '../common/SectionTitle';
import TopSalesList from './TopSalesList';

const TopSales: React.FC = () => {
  const { data: bestSellerData, isLoading: isLoadingBestSellerData } = useFetchTopSellingItems();

  return (
    <div className="py-8">
      <SectionTitle title={TOP_FIVE_SALES} subtitle={LAST_30_DAYS} />
      <div className="py-6 overflow-x-scroll flex">
        {isLoadingBestSellerData ? <Spinner radius={20} /> : <TopSalesList bestSellerData={bestSellerData} />}
      </div>
    </div>
  );
};

export default TopSales;
