import React, { useContext } from 'react';
import { Typography, useTheme } from '@zydalabs/zac-react';
import cx from 'classnames';

import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { DEFAULT_PRODUCT_IMAGE } from 'constants/defaultProductImage';
import { SALES, SOLD_QUANTITY } from 'constants/translations';
import { useMobile } from 'hooks';
import { AnalyticsProduct } from 'service/types/generated/backend_client_index';

interface IndexType {
  index: number;
}
type ProductCardProps = IndexType & AnalyticsProduct;
const ProductCard: React.FC<ProductCardProps> = ({ index, photoUrl, titleAr, titleEn, totalSales, quantitySold }) => {
  const { colors } = useTheme();
  const isMobile = useMobile();
  const { lang, translate } = useContext(localeContext);
  const {
    selectedStore: { currency },
  } = useContext(userContext);
  return (
    <div className="w-full p-6 flex lg:flex-row flex-col gap-4">
      <div className="flex md:flex-col flex-row md:min-w-[96px]">
        <img
          src={photoUrl || DEFAULT_PRODUCT_IMAGE}
          alt="best-selling-pic"
          className={cx(isMobile && 'min-w-[190px]')}
          width={96}
          height={96}
        />
      </div>
      <div className="w-full flex flex-col">
        <div className="flex md:flex-row flex-col">
          <div className="p-1 w-8 h-8 border bg-gray-300 justify-center flex">{index}</div>
          <div className="flex py-3 md:px-3 md:py-0">
            <Typography variant="heading16">{lang === 'en' ? titleEn : titleAr}</Typography>
          </div>
        </div>

        <div className="flex md:flex-row flex-col md:pt-4 pt-0 md:justify-between w-full">
          <div className="flex flex-col py-3 md:py-0">
            <Typography variant="element14" color={colors.positive.secondary}>
              {translate(SOLD_QUANTITY)}
            </Typography>
            <Typography variant="body14">{quantitySold}</Typography>
          </div>
          <div className="flex flex-col">
            <Typography variant="element14" color={colors.positive.secondary}>
              {translate(SALES)}
            </Typography>
            <Typography variant="body14">
              {currency && lang === 'en' ? currency.titleEn : currency.titleAr}
              {totalSales.toFixed(currency.decimals)}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
